document.addEventListener("DOMContentLoaded", () => {
  let session_id = document.getElementById("session-id");

  // May not exist - because this JS loads on every page, ugh.
  //
  // TODO: Figure out how to only load this on the page that needs it,
  // probably with a <%= vite_javascript_tag %> on the page instead of
  // including this JS in application.js -> channels -> this
  if (!session_id) {
    return;
  }

  // The ActionCable stuff below is cool and all, but for whatever reason it
  // does not work during rspec/Capybara tests. So we're just going to force
  // a refresh here, and that'll be a fine fallback for production too.
  // The URL that this page is on will just re-check for success, and render the
  // same page again (and refresh again) if it's not ready.
  setTimeout(() => {
    window.location.reload();
  }, 2000);
});
